@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-ExtraBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-ExtraBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Bold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Bold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Bold.ttf') format('truetype');
        font-weight: bold;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Regular.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Regular.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Regular.ttf') format('truetype');
        font-weight: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-SemiBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-Light.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-Light.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-Light.ttf') format('truetype');
        font-weight: 300;
        font-display: swap;
    }

    @font-face {
        font-family: 'Manrope';
        src:
            url('../public/fonts/manrope/Manrope-SemiBold.otf') format('opentype'),
            url('../public/fonts/manrope/Manrope-SemiBold.woff2') format('woff2'),
            url('../public/fonts/manrope/Manrope-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-display: swap;
    }
}

@layer components {
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .glass-effect {
            /* From https://css.glass */
            background: rgba(255, 255, 255, 0.08);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5.5px);
            -webkit-backdrop-filter: blur(5.5px);
        }
    }

    /* fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .glass-effect {
            background-color: rgba(var(--shadow), 0.6) !important;
        }
    }
}

html,
body,
#__next {
    font-family: Manrope, system-ui, sans-serif;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}
/* Tailwindcss pre-flight interferes with Mantine button components visibility.
In order to fix that we need to disabling pre-flight in the config, which leads to
an additional 8px margin on the body. This is a hack to offset that margin */
body,
main {
    padding: 0;
    margin: 0;
}

*,
::before,
::after {
    border-width: 0;
    border-style: solid;
    /* border-color:rgba(255, 255, 255, 0.3); */
}

* {
    box-sizing: border-box;
}

input {
    background: transparent;
}

@keyframes terminal {
    from {
        transform: translate(100%);
    }
    to {
        transform: translate(-100%);
    }
}

@keyframes globeDotLayer {
    from {
        width: 0px;
    }
    50% {
        width: 100px;
    }
    to {
        width: 0px;
    }
}

@keyframes ping {
    75%,
    100% {
        transform: scale(2);
        opacity: 0.2;
        background: #91a7f2;
    }
}
@keyframes eventCard {
    from {
        transform: translate(500px);
    }
    33% {
        transform: translate(0px);
    }
    85% {
        transform: translate(0px);
    }
    to {
        transform: translate(0px, 200px);
    }
}

.hideScroll::-webkit-scrollbar {
    display: none;
}
.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.mapbox-logo {
    display: none;
}
.mapboxgl-ctrl-logo {
    display: none !important;
}
.mapbox-improve-map {
    display: none;
}
.mapboxgl-ctrl-compass {
    display: none;
}
